<template>
	<v-dialog :value="true" min-width="300" max-width="500" persistent noClickAnimation overlay-opacity="0.9" overlay-color="black">
		<v-card>
			<v-card-title>Add Quicktimer Option</v-card-title>

			<v-card-text>
				<v-alert v-if="modalErrorMessage" color="red" border="bottom" class="my-3" v-html="modalErrorMessage"></v-alert>

				<v-text-field label="Name *" v-model="name" :disabled="saving" required dense hide-details outlined class="my-3"></v-text-field>

				<v-autocomplete v-model="project" :items="projectListArray" label="Project *" :disabled="saving" dense hide-details outlined class="mt-3"></v-autocomplete>

				<v-autocomplete v-model="job" :items="$insight.helpers.getTimerJobs()" label="Job *" :disabled="saving" dense hide-details outlined class="my-3"></v-autocomplete>

				<v-textarea label="Description" v-model="description" :disabled="saving" rows="3" hide-details auto-grow outlined dense class="my-3"></v-textarea>

			</v-card-text>

			<v-card-actions>
				<v-btn :disabled="saving" @click="modal.trigger('close')" class="ml-auto mr-3">Close</v-btn>
				<v-btn color="primary" :disabled="!canSubmit" @click="submit" :loading="saving">
					Create
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
	export default {
		name: "quickTimer",

		props: {
			modal: {
				type: Object,
				required: true,
			},
		},

		data() {
			return {
				saving: false,
				modalErrorMessage: false,

				name: "",
				project: "",
				job: "",
				description: "",
			};
		},

		computed: {
			
			projectListArray() {
				let projects = [];

				this.$db
					.findModels("project", (project) => {
						return project.status !== "closed" && project.client_id > 0;
					})
					.forEach((project) => {
						let client = this.$db.getModel("client", project.client_id);
						if (client) {
							projects.push({
								value: project.id,
								text: client.name + " : " + project.name,
							});
						}
					});
					return projects;
			},

			canSubmit() {
				return this.name && this.project && this.job;
			},
		},

		methods: {
			submit() {
				this.saving = true;

				let me = this.$store.getters.userData,
					quickTimerOptions = me.meta_quicktimer_options || [];

				if (me.meta_quicktimer_options !== null) {
					try {
						quickTimerOptions = JSON.parse(quickTimerOptions);
					} catch (e) {}
				} else {
					quickTimerOptions.push({
						name: "Lunch",
						project_id: 359,
						job: "Digital Attic:Break",
						description: "Lunch",
					});
				}

				quickTimerOptions.push({
					name: this.name,
					project_id: parseInt(this.project),
					job: this.job,
					description: this.description,
				});

				this.$xhrRequest
					.send("put", `/api/person/${me.id}`, {
						meta_quicktimer_options: quickTimerOptions,
					})
					.then(
						(person) => {
							this.$db.addModels("person", person);
							this.modal.trigger("save close", person);
						},
						(xhr) => {
							this.saving = false;

							let errors = this.$insight.helpers.getRequestErrors(
								xhr
							);
							errors = this.$insight.helpers.flattenRequestErrors(
								errors
							);

							this.modalErrorMessage = errors.join("<br>");
						}
					);
			},
		},
	};
</script>